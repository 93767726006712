<script setup lang="ts">
import type { Appointment } from '@/types/calendar'

defineProps<{
  appointment: Appointment
  showCommentsCount?: boolean
  showRelativeTime?: boolean
}>()

const nuxtApp = useNuxtApp()
</script>

<template>
  <div class="space-x-2">
    <UBadge v-if="appointment.cancelled_at" color="red" label="Desmarcada" />

    <UBadge
      v-else-if="
        nuxtApp.$dayjs(appointment.schedule_start).isToday() &&
        nuxtApp.$dayjs().isSameOrAfter(appointment.schedule_start) &&
        nuxtApp.$dayjs().isSameOrBefore(appointment.schedule_end)
      "
      label="A Decorrer"
    />

    <UBadge
      v-if="
        !showRelativeTime &&
        nuxtApp.$dayjs(appointment.schedule_start).isToday()
      "
      color="green"
      label="Hoje"
    />

    <UBadge
      v-else-if="
        showRelativeTime ||
        nuxtApp.$dayjs().isBefore(appointment.schedule_start)
      "
      :label="nuxtApp.$dayjs(appointment.schedule_start).fromNow()"
    />

    <UBadge
      v-else-if="
        !nuxtApp.$dayjs().isBefore(nuxtApp.$dayjs(appointment.schedule_start))
      "
      color="amber"
      :label="nuxtApp.$dayjs(appointment.schedule_start).fromNow()"
    />

    <UBadge
      v-for="carer in appointment.carers"
      :key="carer"
      color="teal"
      :label="getCarerName(carer)"
    />

    <UBadge
      v-if="appointment.schedule_all_day"
      color="orange"
      label="Dia Todo"
    />

    <UBadge
      v-if="showCommentsCount && appointment.comments_count"
      color="violet"
    >
      <UIcon name="i-heroicons-chat-bubble-oval-left-ellipsis-solid" />
      <span class="ml-1">{{ appointment.comments_count }}</span>
    </UBadge>
  </div>
</template>
